import React from 'react';
import Base from './components/Base'
import './App.css';

function App() {
  return (
    <Base/>
  )
}

export default App;
