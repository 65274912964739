import PhotoUpload from './PhotoUpload'

function Base() {

    function send(formData) {
        const data = {
            'message':formData.get("message"),
            'author':formData.get("author")
          }
        fetch('/api/ticker-tape/', {
    
          method: 'POST', 
          mode: 'cors', 
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(data)
    
        })
        
      }
        
    return (
            <div>
                <div className="w-full">
                    <form action={send} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                        <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="author">
                            Your Name
                        </label>
                        <input name="author" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="author" type="text" placeholder="Your Name" />
                        </div>
                        <div className="mb-6">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
                            Your Message
                        </label>
                        <textarea name="message" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="message" type="text" placeholder="" />
                        </div>

                        
                        {/* <PhotoUpload /> */}


                        <div className="flex items-center justify-between">
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                            Send
                        </button>
                        </div>
                    </form>
                    <p className="text-center text-gray-500 text-xs">
                        &#x2764; Bailey & Jeramy
                    </p>
                    </div>
            </div>
    );
}

export default Base;